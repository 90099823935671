import { Injectable } from '@angular/core';
// import { Firestore, collectionData, collection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { DocumentData } from 'rxfire/firestore/interfaces';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class CrudService {


  constructor(
    public fireDB: AngularFirestore
  ) {

  }
  getImages(docID: string): AngularFirestoreCollection<DocumentData[]> {
    return this.fireDB.collection("images", (ref) => ref.where("idProduct", "==", docID))
  }

  get(table: string, docID: string): AngularFirestoreDocument<any> {
    return this.fireDB.collection(table).doc(docID);
  }
  getAll(table: string): AngularFirestoreCollection<DocumentData[]> {
    return this.fireDB.collection(table);
  }
  add(table: string, model): Promise<any> {
    model.id = this.fireDB.createId();
    return this.fireDB.collection(table).add(model);
  }
  // create(table: string, model: any): Promise<any> {
  //   model.id = this.fireDB.createId();
  //   return this.fireDB.collection(table).add(model);
  // }

  // getAll(table: string): AngularFirestoreCollection<any> {
  //   return this.afs.collection(table);
  // }
  update(table: string, model: any): Promise<any> {
    return this.fireDB.collection(table).doc(model.id).update(model);
  }
  delete(table: string, key: string): Promise<any> {
    return this.fireDB.collection(table).doc(key).delete();
  }
  // public login(user: any): Promise<any> {
  //   return this.makeLogin(user);
  // }
  // private makeLogin(user: any): Promise<any> {
  //   return this.fireDBo.signInWithEmailAndPassword(user.username, user.password)
  // }
}
