<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Creación/Edición de Producto</h5>
                </div>
                <div class="card-body">
                    <div class="row product-adding">
                        <div class="col-xl-5">
                            <div class="add-product">
                                <div class="row" *ngIf="!isNew">
                                    <div class="col-xl-9 xl-50 col-sm-6 col-9">
                                        <ngb-carousel *ngIf="url">
                                            <ng-template ngbSlide *ngFor="let item of url; let i=index">
                                                <div class="picsum-img-wrapper">
                                                    <img [src]="item.src == '' ? 'assets/images/pro3/1.jpg' : item.src" [alt]="item.alt" class="img-fluid image_zoom_1">
                                                </div>
                                            </ng-template>
                                        </ngb-carousel>
                                        <!-- <img src="assets/images/pro3/1.jpg" alt="" class="img-fluid image_zoom_1"> -->
                                    </div>
                                    <div class="col-xl-3 xl-50 col-sm-6 col-3">
                                        <ul class="file-upload-product">
                                            <li *ngFor="let item of url; let i=index" class="mb-2">
                                                <div class="box-input-file mb-3">
                                                    <input class="upload" type="file" multiple accept=".jpg,.png,.jpeg" (change)="readUrl($event,i)">
                                                    <img class="imgUpload" alt="" [src]="item.src == '' ? 'assets/images/pro3/1.jpg' : item.src">
                                                    <i *ngIf="url.length > 1" class="fa fa-trash deleteImg" (click)="deleteImg(item)"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <form [formGroup]="productForm" (ngSubmit)="onSubmit()" autocomplete="off" class="needs-validation">
                                <div class="add-product-form">
                                    <div class="form-group row">
                                        <label for="validationCustom01" class="col-xl-3 col-sm-4 mb-0">Nombre: </label>
                                        <input class="form-control col-xl-8 col-sm-7" id="validationCustom01" type="text" [formControl]="productForm.controls['title']" required>
                                        <div *ngIf="productForm.controls.title.touched && productForm.controls.title.errors?.required" class="text text-danger">
                                            Title Name is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="validationCustom03" class="col-xl-3 col-sm-4 mb-0">Tipo de producto: </label>
                                        <input class="form-control col-xl-8 col-sm-7" id="validationCustom03" type="text" [formControl]="productForm.controls['type']" required="">
                                        <div *ngIf="productForm.controls.type.touched && productForm.controls.type.errors?.required" class="text text-danger">
                                            Product
                                            <Code></Code> is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="brand" class="col-xl-3 col-sm-4 mb-0">Marca: </label>
                                        <input class="form-control col-xl-8 col-sm-7" id="brand" type="text" [formControl]="productForm.controls['brand']" required="">
                                        <div *ngIf="productForm.controls.brand.touched && productForm.controls.brand.errors?.required" class="text text-danger">
                                            Marca
                                            <Code></Code> is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="exampleFormControlSelect1" class="col-xl-3 col-sm-4 mb-0">Categoría:</label>
                                        <select class="form-control digits col-xl-8 col-sm-7" id="exampleFormControlSelect1" [formControl]="productForm.controls['category']">
                                            <option>-- Seleccionar -- </option>
                                            <option *ngFor="let item of categories" [value]="item.id">{{item.category}}</option>
                                        </select>
                                        <div *ngIf="productForm.controls.category.touched && productForm.controls.category.errors?.required" class="text text-danger">
                                            Categoría is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="validationCustom02" class="col-xl-3 col-sm-4 mb-0">Price :</label>
                                        <input class="form-control col-xl-8 col-sm-7" id="validationCustom02" type="number" [formControl]="productForm.controls['price']" required="">
                                        <div *ngIf="productForm.controls.price.touched && productForm.controls.price.errors?.required" class="text text-danger">
                                            Product Price is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="discount" class="col-xl-3 col-sm-4 mb-0">Descuento :</label>
                                        <input class="form-control col-xl-8 col-sm-7" id="discount" type="number" [formControl]="productForm.controls['discount']">
                                        <div *ngIf="productForm.controls.discount.touched && productForm.controls.discount.errors?.required" class="text text-danger">
                                            Product discount is required.
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-3 col-sm-4 mb-0">Stock :</label>
                                        <div class="qty-box1">
                                            <div class="input-group">
                                                <i class="fa fa-minus btnGtr1" (click)=decrement()></i>
                                                <input class="touchspin1 text-center" name="counter" [formControl]="productForm.controls['stock']" type="text">
                                                <i class="fa fa-plus btnLess1" (click)=increment()></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label for="tags" class="col-xl-3 col-sm-4 mb-0">Tags:</label>
                                        <input class="form-control col-xl-8 col-sm-7" id="tags" type="text" [formControl]="productForm.controls['tags']">
                                        <sup>cada tag separado por coma","</sup>
                                    </div>
                                    <div class="form-group row">
                                        <label for="active" class="col-xl-3 col-sm-4 mb-0">Artículo nuevo:</label>
                                        <input class="col-xl-8 col-sm-7" id="active" type="checkbox" [formControl]="productForm.controls['sale']">
                                    </div>
                                    <div class="form-group row">
                                        <label for="new" class="col-xl-3 col-sm-4 mb-0">Mostrar etiqueta promoción temporal:</label>
                                        <input class="col-xl-8 col-sm-7" id="new" type="checkbox" [formControl]="productForm.controls['new']">
                                    </div>
                                    <div class="form-group row">
                                        <label for="new" class="col-xl-3 col-sm-4 mb-0">Producto activo:</label>
                                        <input class="col-xl-8 col-sm-7" id="new" type="checkbox" [formControl]="productForm.controls['status']">
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-xl-3 col-sm-4">Add Description :</label>
                                        <div class="col-xl-8 col-sm-7 pl-0 description-sm">
                                            <ckeditor id="editor1" [formControl]="productForm.controls['description']" required></ckeditor>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row mb-0">
                                    <div class="product-buttons col-sm-8 offset-xl-3 offset-sm-4">
                                        <button type="submit" [disabled]="!productForm.valid" class="btn btn-primary mr-1">Guardar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<script>
    // Replace the <textarea id="editor1"> with a CKEditor 4
    // instance, using default configuration.
    CKEDITOR.replace('editor1');
</script>
<!-- Container-fluid Ends-->