import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class MainService {
    constructor() {

    }
    public getImageBase64(input: any): Promise<Array<string>> {
        return new Promise((resolve, reject) => {
            if (input.files.length) {
                let images: Array<string> = [];
                let count = 0;
                input.files.forEach((el, i) => {
                    count++;
                    const reader = new FileReader();
                    if (el.size / 1024 > environment.maxLength.images) {
                        alert(environment.messages.imagesSize + environment.maxLength.images);
                        resolve(null);
                    }
                    else {
                        reader.readAsDataURL(el);
                        reader.onload = () => {
                            images.push(reader.result.toString());
                            if (count === input.files.length) {
                                console.log(images);

                                resolve(images);
                            }
                        }

                    }
                });
            }
        })

    }
}