import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Images } from 'src/app/shared/interfaces/Product.interface';
import { CrudService } from 'src/app/shared/service/crud.service';
import { orderDB } from "../../../shared/tables/order-list";
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  public order = [];
  public temp = [];
  public closeResult: string;
  public editionOrder: any = {};
  public statusOrders = [
    { status: '1', short: 'Nuevo pedido', description: "form-control btn btn-secondary" },
    { status: '2', short: 'Contactado y a espera de ser cagado', description: "form-control btn btn-primary" },
    { status: '3', short: 'Pagado', description: "form-control btn btn-success" },
    { status: '4', short: 'En proceso de envío', description: "form-control btn btn-warning" },
    { status: '5', short: 'En proceso de entrega', description: "form-control btn btn-warning" },
    { status: '6', short: 'Finalizado', description: "form-control btn btn-success" },
    { status: '7', short: 'Cancelado', description: "form-control btn btn-danger" }
  ]
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(
    private _crudS: CrudService,
    private modalService: NgbModal,
  ) {
    this._crudS.getAll("orders")
      .valueChanges({ idField: "id" })
      .subscribe(
        (r: any) => {
          this.order = r
            .filter(data => data.status === undefined || data.status != '3' || data.status != '4')
            .map((item: any) => {
              item.product = "";
              return item;
            });
          this.temp = this.order;
          this.loadImages();
        },
        err => console.log(err)
      )
    // this.order = orderDB.list_order;
  }
  private loadImages(): void {
    this.order.forEach(item => {
      item.products.forEach(prod => {
        this._crudS.getImages(prod.id)
          .valueChanges({ idField: "id" })
          .subscribe((img: Images[]) => {
            prod.images = img;
            if (img.length > 0) {
              item.product += `<img src="${img[0].src}" class='img-30 mr-2'>`;
            }
            else item.product += `<img src="./assets/images/product-list/3.jpg" class='img-30 mr-2'>`;
          });
      });
    });
  }

  public getStatusType(status: string): string {
    switch (status.toString()) {
      case '1':
        return "<span class='badge badge-secondary'>Nuevo pedido</span>";
        break;
      case '2':
        return "<span class='badge badge-primary'>Contactado y a espera de ser pagado</span>";
        break;
      case '3':
        return "<span class='badge badge-success'>Pagado</span>";
        break;
      case '4':
        return "<span class='badge badge-warning'>En proceso de envío</span>";
        break;
      case '5':
        return "<span class='badge badge-warning'>En proceso de entrega</span>";
        break;
      case '6':
        return "<span class='badge badge-success'><b>Finalizado</b></span>";
        break;
      case '7':
        return "<span class='badge badge-danger'>Cancelado u_u</span>";
        break;
      default:
        return "<span class='badge badge-secondary'>Nuevo pedido</span>";
        break;
    }
  }
  setStatus(item, id): void {
    if (confirm(`¿Esta seguro de asignar el estatus ${item.short} al pedido ${id}?`)) {
      let Track = {
        order: id,
        amount: this.editionOrder.total,
        status: this.editionOrder.status ? this.editionOrder.status : '1',
        newStatus: item.status,
        timeStamp: (new Date()).getTime()
      };
      delete this.editionOrder.product;
      this.editionOrder.products.forEach(prod => {
        prod.images = [];
      });
      this.editionOrder.status = item.status;
      this._crudS.add("orders_tracking", Track);
      this._crudS.update("orders", this.editionOrder)
        .then(r => {
          alert("Estatus asignado correctamente");
          this.modalService.dismissAll();
        })
        .catch(err => console.log(err));

    }
  }
  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp
      .filter(d => {
        d.id.toLowerCase().indexOf(val);
        return d.id.toLowerCase().indexOf(val) !== -1
      });
    console.log(temp, val);


    // update the rows
    this.order = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
  public onSelect(data: any, modal: any): void {
    this.editionOrder = data;
    this.open(modal);
  }
  private open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: "xl" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
  }

}
