import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Category } from 'src/app/shared/interfaces/category.interface';
import { Images } from 'src/app/shared/interfaces/Product.interface';
import { CrudService } from 'src/app/shared/service/crud.service';
@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss']
})
export class AddProductComponent implements OnInit {
  public productForm: FormGroup;
  public counter: number = 1;
  public id: string = "";
  public isNew: boolean = true;
  public url: Images[] = [{ src: "", alt: "" }];
  public categories: Category[] = [];

  private useModel: string = "products";


  constructor(
    private fb: FormBuilder,
    private _crusService: CrudService,
    private _router: Router,
    private _ar: ActivatedRoute
  ) {
    this.productForm = this.fb.group({
      id: "",
      title: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      description: ['', [Validators.required]],
      type: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      brand: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+[a-zA-Z]$')]],
      category: ['', [Validators.required]],
      price: [0, [Validators.required]],
      sale: [false],
      discount: [null],
      stock: [0],
      "new": [false],
      tags: [""],
      collection: [],
      variants: [[]],
      status: false
    });
    this.loadCatalogs();

  }
  private loadCatalogs() {
    this._crusService.getAll("categories")
      .valueChanges({ idField: "id" })
      .subscribe(
        (r: any[]) => {
          this.categories = r.filter(e => e.status);
          this.validateData();
        },
        err => console.log(err)
      );
  }
  private validateData() {
    this._ar.paramMap.subscribe(r => {
      let data = r.get("id");
      if (data) {
        this._crusService.get('products', data)
          .valueChanges({ idField: "id" })
          .subscribe(
            r => {
              this.productForm.patchValue(r);
              this.isNew = false;
              this.id = data;
              this.loadImages();
            },
            err => console.log(err))
      }
      else console.log(data);
    })

  }
  private loadImages(): void {
    this._crusService.getImages(this.id)
      .valueChanges({ idField: "id" })
      .subscribe(
        (r: any[]) => {
          this.url = r;
          if (this.url.length == 0) this.url.push({ src: "", alt: "" });
        },
        err => console.log(err))
  }

  increment() {
    let x = +this.productForm.controls.stock.value;
    x += 1;
    this.productForm.controls.stock.patchValue(x);
  }

  decrement() {
    let x = +this.productForm.controls.stock.value;
    if (x > 0) x -= 1;
    this.productForm.controls.stock.patchValue(x);
  }

  //FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0)
      return;
    //Image upload validation
    event.target.files.forEach(element => {
      var mimeType = element.type;
      if (mimeType.match(/image\/*/) == null) {
        return;
      }
      // Image upload
      var reader = new FileReader();
      let name = element.name;
      reader.readAsDataURL(element);
      reader.onload = (_event) => {
        if (this.url[0].src == "" && this.url[0].alt == "") {
          this.url[0].src = reader.result.toString();
          this.url[0].alt = name;
        }
        else this.url.push({ src: reader.result.toString(), alt: name });
      }
    });
  }

  ngOnInit() {
  }
  public onSubmit(): void {
    if (this.productForm.valid) {
      this.saveProduct();
    }
    else {
      console.log(this.productForm.controls["id"].errors);
      console.log(this.productForm.controls["title"].errors);
      console.log(this.productForm.controls["description"].errors);
      console.log(this.productForm.controls["type"].errors);
      console.log(this.productForm.controls["brand"].errors);
      console.log(this.productForm.controls["category"].errors);
      console.log(this.productForm.controls["price"].errors);
      console.log(this.productForm.controls["sale"].errors);
      console.log(this.productForm.controls["discount"].errors);
      console.log(this.productForm.controls["stock"].errors);
      console.log(this.productForm.controls["tags"].errors);
      console.log(this.productForm.controls["collection"].errors);
      console.log(this.productForm.controls["variants"].errors);
    }
  }
  private saveProduct(): void {
    if (this.productForm.value.id == "") {
      this._crusService
        .add(this.useModel, this.productForm.value)
        .then(r => {
          console.log(r);
          alert("Producto creado con éxito");
          this._router.navigate(["/products/physical/product-list"]);
        });
    }
    else {
      let imgs: Images[] = this.url;
      let prod = this.productForm.value;
      prod.images = [];
      console.log(prod);

      this._crusService
        .update(this.useModel, prod)
        .then(r => {
          this.saveImages(imgs, this.productForm.value.id, "Producto actualizado con exito");
        });
    }
  }
  private saveImages(imgs: Images[], id: string, msg: string): void {
    imgs.forEach(e => {
      if (e.idProduct) this._crusService.update("images", e);
      else if (e.id != "" && e.src != "") {
        e.idProduct = id;
        this._crusService.add("images", e)
      }
    });
    setTimeout(() => {
      alert(msg);
      this._router.navigate(["/products/physical/product-list"]);
    }, 2000);

  }
  public deleteImg(item: Images): void {
    this._crusService.delete("images", item.id)
      .then()
      .catch(err => {
        console.log(err);
        alert("Ocurrio un error al intentar eliminar la imágen, favor de intentarlo más tarde.")
      })
  }
}
