import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CrudService } from 'src/app/shared/service/crud.service';
import { Category } from 'src/app/shared/interfaces/category.interface';
import { MainService } from 'src/app/shared/service/main.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  public closeResult: string;
  public categories: Category[] = [];

  public form!: FormGroup;
  private modelUse = "categories";

  constructor(
    private modalService: NgbModal,
    private _service: MainService,
    private _crudS: CrudService,
    public formBuilder: FormBuilder,
  ) {
    this.form = this.formBuilder.group(
      {
        id: "",
        img: [null, Validators.compose([Validators.required])],
        category: [null, Validators.compose([Validators.required])],
        status: [false]
      });

    this._crudS.getAll(this.modelUse)
      .valueChanges({ idField: "id" })
      .subscribe(
        (r: any) => {
          console.log(r);
          r.map(r => {
            r.rimg = r.img;
            r.rstatus = r.status;
            r.img = `<img src='${r.img}' class='imgTable'>`;
            r.status = `<i class='fa fa-circle font-${r.status ? "success" : "danger"} f-12'></i>`;
          });
          this.categories = r;
        },
        err => console.log(err)
      );
    // this.categories = categoryDB.category;
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }


  public settings = {
    mode: "external",
    actions: {
      hide: true,
      position: 'right',
      add: false,
    },
    columns: {
      id: {
        title: 'ID',
        hide: true
      },
      img: {
        title: 'Image',
        type: 'html',
      },
      category: {
        title: 'Name'
      },
      status: {
        title: 'Status',
        type: 'html',
      }
    },
  };

  ngOnInit() {
  }
  public fileSelected(ev: any): void {
    console.log(ev.files);
    this._service.getImageBase64(ev).then(r => {
      if (r != null) {
        this.form.controls.img.patchValue(r[0]);
      }
    });
  }
  public onSubmit() {
    console.log(this.form.value, this.form.valid, this.form.errors);
    if (this.form.valid) {
      if (this.form.value.id === "")
        this._crudS.add(this.modelUse, this.form.value)
          .then(r => this.modalService.dismissAll())
          .catch(err => console.log(err));
      else
        this._crudS.update(this.modelUse, this.form.value)
          .then(r => this.modalService.dismissAll())
          .catch(err => console.log(err));
    }
    else return false;
  }
  public resetForm(content: any): void {
    this.form.patchValue({
      id: "",
      img: null,
      category: null,
      status: null
    });
    this.open(content);
  }
  public delData(ev: any): void {
    if (confirm(`Al eliminar esta la categoría ${ev.data.category}, se eliminaran todos los productos relacionados a este.\n¿Desea continuar?`)) {
      this._crudS.delete(this.modelUse, ev.data.id).then(r => alert("Categoría eliminada con éxito."));
    }
  }
  public editData(ev: any, content: any): void {
    let x: any = ev.data;
    x.img = x.rimg;
    x.status = x.rstatus;
    delete x.rimg;
    delete x.rstatus;
    this.form.patchValue(x);
    this.open(content);
  }
}
