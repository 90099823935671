<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Manage Order</h5>
                </div>
                <div class="card-body">
                    <div class="custom-datatable">
                        <input type='text' class="filter-ngx form-control" placeholder='Search...' (keyup)='updateFilter($event)' />
                        <ngx-datatable class="bootstrap" [rows]="order" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [limit]="5">
                            <ngx-datatable-column prop="id"></ngx-datatable-column>
                            <ngx-datatable-column name="Productos" prop="product"></ngx-datatable-column>
                            <ngx-datatable-column prop="status">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    <span [innerHtml]="getStatusType(value?value:'1')"></span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="createdDate">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value | date}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Total" prop="total">
                                <ng-template let-value="value" ngx-datatable-cell-template>
                                    {{value | currency}}
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column name="Acciones">
                                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                                    <button class="btn btn-transparent" (click)='onSelect(row,content)' data-toggle="modal" data-original-title="test" data-target="#exampleModal">
                                        <i class="fa fa-edit"></i>
                                    </button>
                                </ng-template>
                            </ngx-datatable-column>
                        </ngx-datatable>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">{{editionOrder.id}} -> <span [innerHtml]="getStatusType(editionOrder.status ? editionOrder.status:'1')"></span></h5>
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12">
                                        <div class="row mb-2">
                                            <div class="col-md-12 text-right">
                                                <h5>Datos del cliente</h5>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Nombre completo:</b></div>
                                            <div class="col-md-8">{{editionOrder.firstname}} {{editionOrder.lastname}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Teléfono:</b></div>
                                            <div class="col-md-8">{{editionOrder.phone}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Correo:</b></div>
                                            <div class="col-md-8">{{editionOrder.email}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Fecha Hora y fecha:</b></div>
                                            <div class="col-md-8">{{editionOrder.createdDate|date:'short'}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-12 text-right">
                                                <h5>Dirección del cliente</h5>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Dirección completa:</b></div>
                                            <div class="col-md-8">{{editionOrder.address}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Estado:</b></div>
                                            <div class="col-md-8">{{editionOrder.state}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"><b>Municipio/Alcaldía</b></div>
                                            <div class="col-md-8">{{editionOrder.town}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-12 text-right">
                                                <h5>Detalle del pedido</h5>
                                            </div>
                                        </div>
                                        <div class="row mb-2" *ngFor="let item of editionOrder.products">
                                            <div class="col-md-2">
                                                <img width="100%" [src]="item.images[0]?item.images[0].src:'assets/images/electronics/product/25.jpg'">
                                            </div>
                                            <div class="col-md-7 text-left">{{item.title}}</div>
                                            <div class="col-md-3 text-right">{{item.price|currency}}</div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-2">
                                            </div>
                                            <div class="col-md-7 text-right"><b>Total: </b></div>
                                            <div class="col-md-3 text-right">{{editionOrder.total | currency}}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-12">
                                        <div class="row">
                                            <div class="col-md-12 mb-2 text-center" *ngFor="let item of statusOrders">
                                                <button (click)="setStatus(item,editionOrder.id)" [class]="item.description">{{item.short}}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->