<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Products Category</h5>
                </div>
                <div class="card-body">
                    <div class="btn-popup pull-right">
                        <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test" data-target="#exampleModal" (click)="resetForm(content)">Add Category</button>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <h5 class="modal-title f-w-600" id="exampleModalLabel">Add Category</h5>
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off" class="needs-validation">
                                <div class="modal-body">
                                    <div class="form">
                                        <div class="form-group">
                                            <label for="validationCustom01" class="mb-1">Category Name :</label>
                                            <input class="form-control" formControlName="category" id="validationCustom01" type="text" required>
                                            <label *ngIf="form.controls.category.errors?.required" class="label-danger">* es obligatorio</label>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label for="validationCustom02" class="mb-1">Category Image:</label>
                                            <input class="form-control" id="validationCustom02" type="file" accept=".jpg,.png,.jpeg,.svg" #input (change)="fileSelected(input)" required>
                                            <label *ngIf="form.controls.img.errors?.required" class="label-danger">* es obligatorio</label>
                                        </div>
                                        <div class="form-group">
                                            <div class="row justify-content-center">
                                                <div class="col-md-9">
                                                    <img *ngIf="form.controls.img.value" [src]="form.controls.img.value" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label for="validationCustom03" class="mb-1">Activo: </label>
                                            <input id="validationCustom03" type="checkbox" formControlName="status">
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-primary">Save</button>
                                    <button class="btn btn-secondary" type="button" data-dismiss="modal" (click)="modal.dismiss('Cross click')">Close</button>
                                </div>
                            </form>
                        </ng-template>
                    </div>
                    <div id="batchDelete" class="category-table custom-datatable">
                        <div class="table-responsive">
                            <ng2-smart-table [settings]="settings" (edit)="editData($event,content)" (delete)="delData($event)" [source]="categories"></ng2-smart-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->