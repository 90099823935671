// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'ecommerce-srj-bazar',
    appId: '1:443144138218:web:fab9479faf6d23c60e1693',
    storageBucket: 'ecommerce-srj-bazar.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAmmBYYXM4Dt5shG07E6mPlEO7VZDkBjnk',
    authDomain: 'ecommerce-srj-bazar.firebaseapp.com',
    messagingSenderId: '443144138218',
    measurementId: 'G-N5Q2H190KT',
  },
  maxLength: {
    images: 500
  },
  messages: {
    imagesSize: "El tamaño de la imagen máximo es: "
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
