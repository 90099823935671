import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Images, Product } from 'src/app/shared/interfaces/Product.interface';
import { CrudService } from 'src/app/shared/service/crud.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  public product_list: Product[] = []
  private useModel: string = "products";
  constructor(
    private _crudService: CrudService,
  ) {
    this._crudService.getAll(this.useModel)
      .valueChanges({ idField: "id" })
      .pipe(
      )
      .subscribe((r: any) => {
        this.product_list = r;
        this.setImages();
      },
        err => {
          console.log(err);
          alert("Ocurrio un error al cargar los productos, por favor intenta más tarde.")
        }
      );
  }
  private setImages() {
    this.product_list.forEach(e => {
      e.images = [];
      this._crudService.getImages(e.id)
        .valueChanges({ idField: "id" })
        .subscribe((img: Images[]) => { e.images = img });
    })
  }


  ngOnInit() { }


}
