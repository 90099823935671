<div class="container-fluid">
    <div class="row products-admin ratio_asos">
        <div class="col-xl-3 col-sm-6" *ngFor="let product of product_list">
            <div class="card">
                <div class="card-body product-box">
                    <div class="img-wrapper">
                        <div class="lable-block"><span class="lable3" *ngIf="product.tag == 'new'">new</span> <span class="lable4" *ngIf="product.sale == 'on sale'">on sale</span></div>
                        <div class="front">
                            <a href="javascript:void(0)">
                                <img *ngIf="product.images.length > 0" [src]="product.images[0]?.src" class="img-fluid blur-up lazyload bg-img" alt="">
                                <img *ngIf="product.images.length == 0" src="assets/images/pro3/1.jpg" class="img-fluid blur-up lazyload bg-img" alt="">
                            </a>
                            <div class="product-hover">
                                <ul>
                                    <li>
                                        <a class="btn" [routerLink]="['/products/physical/edit-product',product.id]">
                                            <i class="icon-pencil-alt"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <button class="btn" type="button" data-toggle="modal" data-target="#exampleModalCenter" data-original-title="" title=""><i
                                                class="icon-trash"></i></button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="product-detail">
                        <!-- <div class="rating">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                        </div> -->
                        <a href="javascript:void(0)">
                            <h6>{{product.title}}</h6>
                        </a>
                        <h4>{{( (product.discount==0)? product.price :+product.price * (product.discount/100) )| currency }} <del *ngIf="product.discount != 0">{{product.price}}</del></h4>
                        <!-- <ul class="color-variant">
                            <li class="bg-light0"></li>
                            <li class="bg-light1"></li>
                            <li class="bg-light2"></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>